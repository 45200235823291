import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DsvsPermissionService, DsvsSearchEvent, DsvsSettings, DsvsTableColumn } from '@dsvs/dsvs-shared-ui-lib';
import { saveAs } from 'file-saver';
import { MenuItem, MessageService } from 'primeng/api';
import { ZapPermissions } from '../../../../../enums/zap-permissions.enum';
import { resolveFileName } from '../../../../../helper/download-helper';
import { errorMessage, infoMessage } from '../../../../../helper/exception-helper';
import { FormularHalItem } from '../../../../shared/hal/formular.hal.item';
import { VersionHalItem, VersionHalPage } from '../../../../shared/hal/version.hal.item';
import { SubscriptionsContainer } from '../../../../shared/helper/SubscriptionsContainer';
import { Language } from '../../../../shared/interfaces/dtos/language';
import { LanguageServiceImpl } from '../../../../shared/services/language.service';
import { VersionServiceImpl } from '../../../../shared/services/version.service';

@Component({
  selector: 'fes-version-table',
  templateUrl: './version-table.component.html',
  styleUrls: ['./version-table.component.scss']
})
export class VersionTableComponent implements OnInit, OnDestroy {

  versions: VersionHalPage;
  _version: VersionHalItem;
  selectedLanguage: Language;
  languages: Language[];
  @Input()
  isLoading = false;
  @Input()
  isEditable = false;
  @Output()
  createDevelopmentVersion = new EventEmitter<VersionHalItem>();
  @Output()
  refreshData = new EventEmitter<void>();
  versionTableColums: DsvsTableColumn[];
  showTranslationDialog: boolean = false;
  availableLanguageExists: boolean = false;
  subscriptions: SubscriptionsContainer = new SubscriptionsContainer();

  constructor(
    private fesSettings: DsvsSettings,
    private versionService: VersionServiceImpl,
    private messageService: MessageService,
    public permissionService: DsvsPermissionService,
    public languageService: LanguageServiceImpl
  ) {
  }

  _formular: FormularHalItem;

  @Input()
  set formular(formular: FormularHalItem) {
    this._formular = formular;
    if (formular) {
      this.loadVersions(formular);
    }
  }

  ngOnInit() {
    this.versionTableColums = this.getFormularColums();
    this.subscriptions.add = this.languageService.getActiveLanguages().subscribe(activeLanguages => {
        this.languages = activeLanguages;
        //Check if there available languages for the translation dialog
        this.availableLanguageExists = this.hasAvailableLanguages(this.languages);
      },
      err => {
        console.error(err);
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.dispose();
  }

  onChange(page: DsvsSearchEvent) {
    if (this._formular) {
      this.isLoading = true;
      this._formular.versions.setParams({
        sort: page.sort.map(curr => curr.property + ',' + curr.order.toLocaleLowerCase()),
        size: page.size,
        page: page.number
      }).async.subscribe(
        versions => {
          this.isLoading = false;
          this.versions = versions;
        },
        err => {
          console.error(err);
          this.isLoading = false;
        }
      );
    }
  }

  loadVersions(formular: FormularHalItem) {
    this.isLoading = true;
    const initialSize = this.fesSettings.getOptionValue('DTROWS_FES', 10);
    formular.versions
    .setParams({
      sort: '',
      size: initialSize,
      page: 0
    })
    .async.subscribe(
      versions => {
        this.isLoading = false;
        this.versions = versions;
      },
      err => {
        console.error(err);
        this.isLoading = false;
      }
    );
  }

  onActivateVersionClicked(version: VersionHalItem) {
    this.versionService.activateVersion(version.data.id).subscribe({
      next: this.onActivateVersionSuccess.bind(this),
      error: this.activationError.bind(this)
    });
  }

  activationError() {
    this.messageService.add(
      {
        severity: 'error',
        summary: 'Fehler',
        detail: 'Beim Aktivieren ist ein Fehler aufgetreten.'
      }
    );
  }

  onActivateVersionSuccess(): void {
    this.refreshData.emit();
    this.messageService.add(
      {
        severity: 'success',
        summary: 'Version-Upload',
        detail: 'Version erfolgreich aktiviert.'
      }
    );
  }

  onCreateDevelopmentVersionClicked(version: VersionHalItem) {
    this.createDevelopmentVersion.emit(version);
  }

  translateForm() {
    this.showTranslationDialog = false;
    this.messageService.add(infoMessage('Der Download wird erstellt, bitte haben Sie einen Augenblick Geduld'));
    this.languageService.translateForm(this._version.data.id,
      this.selectedLanguage.languageCode).subscribe(response => {
      saveAs(response.body, resolveFileName(response.headers));
    }, error => {
      console.log(error);
      this.messageService.add(errorMessage('Beim Download ist ein Fehler aufgetreten'));
    });
  }

  getSplitButtonItems(version: VersionHalItem): MenuItem[] {

    if (this.isEditable) {

      const items: MenuItem[] = [];

      items.push(
        {
          label: 'Entwicklerversion', icon: 'fa ui-icon-code', command: () => {
            this.onCreateDevelopmentVersionClicked(version);
          }
        }
      );

      items.push(
        {
          label: 'Aktivieren', icon: 'fa ui-icon-check-circle', command: () => {
            this.onActivateVersionClicked(version);
          },
          disabled: version.data ? version.data.active : true
        }
      );

      if (this.permissionService.hasPermission([ZapPermissions.FES_FORM_DELETE])) {

        if (version && version.data && version.data.deleted) {

          items.push({
            label: 'Wiederherstellen',
            icon: 'fa ui-icon-restore',
            command: () => this.restoreVersion(version)
          });

        }
      }

      if (this.permissionService.hasPermission([ZapPermissions.FES_FORM_TRANSLATION_DOWNLOAD])) {
        items.push({
            label: 'Übersetzung',
            icon: 'fa ui-icon-file-download',
            disabled: !this._formular.data.translatable,
            command: () => {this.toggleDialog(version);}
          }
        );
      }

      return items;

    } else {
      return [];
    }
  }

  public closeDialog() {
    this.showTranslationDialog = false;
  }

  /**
   * Checks if there are available languages for the translation dialog.
   * If not, there will be a message shown 'Keine Sprachen verfügbar'.
   * @param languages
   */
  public hasAvailableLanguages(languages: Language[]): boolean {
    return (languages.length !== 0);
  }

  private toggleDialog(version: VersionHalItem): void {
    this._version = version; //Use the selected version for the dialog.
    this.showTranslationDialog = !this.showTranslationDialog;
  }

  private restoreVersion(version: VersionHalItem) {
    this.versionService.restoreVersion(version.data).subscribe(result => {
      this.messageService.add({
        severity: 'success',
        summary: 'Erfolg',
        detail: 'Die Version ' + version.data.version + ' wurde wiederhergestellt'
      });
      this.refreshData.emit();

    }, error => {
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Die Version ' + version.data.version + ' konnte nicht wiederhergestellt werden'
      });
    });

  }

  private getFormularColums(): DsvsTableColumn[] {
    // Some Columns are defined in template! (hence sum(x%) < 100)
    return [
      {field: 'active', header: '', format: 'ACTIVE', sort: true, width: '5%'},
      {field: 'version', header: 'Version', sort: true, width: '10%'},
      {field: 'parentVersion', header: 'Vorgänger', format: 'PARENTVERSION', width: '10%'},
      {field: 'comment', header: 'Änderungshinweis zum Serviceupdate', sort: true, width: '58%'},
      {field: 'lastModifiedDate', header: 'Datum', format: 'DATE', sort: true, width: '10%'},
      {field: '', header: '', format: 'ACTION', width: '7%'}
    ];

  }

}
